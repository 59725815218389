import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";
import { range } from "lodash";

export default class WorkOrderService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getOrder(id: string, client: string) {
    const params = {
      client,
    };
    return ClientAPI.Get(`workorder/${id}`, params);
  }

  async getWorkOrderById(
    client: string,
    id: string,
    fieldnames: string,
    correls: string,
  ) {
    const params = {
      client,
      fieldnames,
      correls,
    };
    return ClientAPI.Get(`workorder/${id}`, params);
  }

  async getOrders(workorderRequest: {
    FilterValues: any;
    SortBy: string;
    SortOrder: string;
    RangeStart: number;
    RangeEnd: number;
    ScheduledWorkcenter: boolean;
    Status: string;
    Workcenters: Array<string>;
    Correls: string;
  }) {
    const {
      FilterValues,
      SortBy,
      SortOrder,
      RangeStart,
      RangeEnd,
      ScheduledWorkcenter,
      Status,
      Workcenters,
      Correls,
    } = workorderRequest;
    const params = {
      ScheduledWorkcenter: ScheduledWorkcenter,
      Status: Status,
      Workcenters: Workcenters,
      correls: Correls,
      rangeStart: RangeStart,
      rangeEnd: RangeEnd,
      SortBy: SortBy,
      SortOrder: SortOrder,
      FilterValues: FilterValues,
    };
    return ClientAPI.Get(`workorder`, params);
  }
  async searchWorkOrders(workorderRequest: {
    FilterValues?: any;
    SortBy?: string;
    SortOrder?: string;
    RangeStart?: number;
    RangeEnd?: number;
    ScheduledWorkcenter?: boolean;
    UnscheduledWorkOrders?: boolean;
    Status?: string;
    Workcenters?: Array<string>;
    Correls?: string;
    OperationStartDate?: string;
    OperationCompDate?: string;
  }) {
    const {
      FilterValues,
      SortBy,
      SortOrder,
      RangeStart,
      RangeEnd,
      ScheduledWorkcenter,
      UnscheduledWorkOrders,
      Status,
      Workcenters,
      Correls,
      OperationCompDate,
      OperationStartDate,
    } = workorderRequest;
    const sortOrder = SortOrder === "-1" ? "DEC" : "";
    // need to revert filterValue names to match the backend
    const transformFilterValues = (filterValues: any): any => {
      const transformedValues: any = {};

      for (const key in filterValues) {
        if (Object.prototype.hasOwnProperty.call(filterValues, key)) {
          const newKey = key.replace(/_/g, ".");
          transformedValues[newKey] = filterValues[key];
        }
      }

      return transformedValues;
    };
    const newFilterValues = transformFilterValues(FilterValues);
    const params = {
      ScheduledWorkcenter: ScheduledWorkcenter,
      Status: Status,
      Workcenters: Workcenters,
      correls: Correls,
      rangeStart: RangeStart?.toString(),
      rangeEnd: RangeEnd?.toString(),
      sortBy: SortBy,
      sortOrder: SortOrder,
      filterValues: newFilterValues,
      UnscheduledWorkOrders: UnscheduledWorkOrders,
      OperationStartDate: OperationStartDate,
      OperationCompDate: OperationCompDate,
    };
    return ClientAPI.Post(`workorder/WorkorderSearch`, params);
  }
  async putOrders(payload: any) {
    const { records, oldRecords } = payload;
    return ClientAPI.Put(`workorder`, { records, oldRecords });
  }
}
